import React from "react";
import About from "../../components/About/About";
import AboutInfo2 from "../../components/About/AboutInfo2";
import Crypto from "../../components/About/Crypto";

import AboutInfo from "../../components/About/AboutInfo";
import AboutInfoRewards from "../../components/About/AboutInfoRewards";

import BannerTwo from "../../components/Banner/BannerTwo";
import ContactTwo from "../../components/Contact/ContactTwo";
import CountDownTwo from "../../components/CountDown/CountDownTwo";
import CounterArea from "../../components/CounterArea/CounterArea";
import DownloadArea from "../../components/DownloadArea/DownloadArea";
import Faq from "../../components/Faq/Faq";
import Newsletter from "../../components/Newsletter/Newsletter";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import TeamTwo from "../../components/Team/TeamTwo";
import WhyChooseUsTwo from "../../components/WhyChooseUs/WhyChooseUsTwo";
import LayoutTwo from "../../layouts/LayoutTwo";
import Sales from "../../components/Sales/Sales";
import NetworkMarketing from "../../components/About/NetworkMarketing";
import PromotionalView from "../../components/About/Promotional";
import BuyTokenView from "../../components/About/BuyTokenView";
import TokenomicsBanner from "../../components/About/TokenomicsBanner";
import SalesBar from "../../components/About/SalesBar";
import MFTStory from "../../components/About/MFTStory";
import BinanceMarketCap from "../../components/About/BinanceMarketCap";
import Guideline from "../../components/Step/Guideline";

const HomeTwo = () => {


  return (
    <LayoutTwo>
      <main>
        <BannerTwo />
        <AboutInfo2 />
        <CountDownTwo />

        <AboutInfo />
        <Guideline />

        <AboutInfoRewards />
        <NetworkMarketing />
        <PromotionalView />
        <SalesBar />

        <TokenomicsBanner />
        <MFTStory />

        {/* <WhyChooseUsTwo /> */}
        {/* <CounterArea /> */}

        <Sales />
        <RoadmapTwo />
        {/* <Crypto /> */}
        <BinanceMarketCap />

        <Faq />
        <ContactTwo />
        <Newsletter />
      </main>
    </LayoutTwo>
  );
};

export default HomeTwo;
