import React, { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import './BuyTokenView.css';
import MFTTokenAbi from '../../assets/tokenabi/MFTTokenAbi.json';
import Web3 from 'web3';
import Select from 'react-select';
import Parse from 'parse';

// Dil çevirileri
const translations = {
  tr: {
    welcome: "Hoş Geldiniz",
    connectWalletDescription: "Satın almak için önce cüzdanınızı bağlamanız gerekiyor.",
    connectWallet: "Cüzdanı Bağla",
    connected: "Bağlandı",
    next: "İleri",
    buyMFT: "MFT Satın Al",
    howManyMFT: "Kaç MFT satın almak istiyorsunuz?",
    paymentMethod: "Ödeme Yöntemi",
    equivalent: "Karşılık:",
    back: "Geri",
    buy: "Satın Al",
    transactionComplete: "İşlem Tamamlandı",
    purchaseSuccessful: "MFT token satın alma işlemi başarılı.",
    amountToPay: "Ödenecek Tutar:",
    gasFee: "Gas Ücreti:",
    totalAmount: "Toplam Ödenecek:",
    proceedToPayment: "Ödeme Adımına Geç",
    minimum1000: "Minimum 1000 MFT satın almalısınız.",
    walletNotFound: "Cüzdan bulunamadı. Lütfen MetaMask yükleyin.",
    walletConnectionError: "Cüzdan bağlantısında hata. Lütfen tekrar deneyin.",
    selectAccount: "Hesap seçin",
    account: "Hesap",
    continue: "Devam Et",
    selectAccountDescription: "Lütfen hesabınızı seçerek devam edin",

  },
  en: {
    welcome: "Welcome",
    connectWalletDescription: "To buy, you need to connect your wallet first.",
    connectWallet: "Connect Wallet",
    connected: "Connected",
    next: "Next",
    buyMFT: "Buy MFT",
    howManyMFT: "How many MFT would you like to buy?",
    paymentMethod: "Payment Method",
    equivalent: "Equivalent:",
    back: "Back",
    buy: "Buy",
    transactionComplete: "Transaction Complete",
    purchaseSuccessful: "MFT token purchase was successful.",
    amountToPay: "Amount to Pay:",
    gasFee: "Gas Fee:",
    totalAmount: "Total Amount:",
    proceedToPayment: "Proceed to Payment",
    minimum1000: "You must buy a minimum of 1000 MFT.",
    walletNotFound: "Wallet not found. Please install MetaMask.",
    walletConnectionError: "Error connecting wallet. Please try again.",
    selectAccount: "Select an account",
    account: "Account",
    continue: "Continue",
    selectAccountDescription: "Please select your account to continue",

  },
  de: {
    welcome: "Willkommen",
    connectWalletDescription: "Um zu kaufen, müssen Sie zuerst Ihre Wallet verbinden.",
    connectWallet: "Wallet verbinden",
    connected: "Verbunden",
    next: "Weiter",
    buyMFT: "MFT kaufen",
    howManyMFT: "Wie viele MFT möchten Sie kaufen?",
    paymentMethod: "Zahlungsmethode",
    equivalent: "Gegenwert:",
    back: "Zurück",
    buy: "Kaufen",
    transactionComplete: "Transaktion abgeschlossen",
    purchaseSuccessful: "Der Kauf von MFT-Token war erfolgreich.",
    amountToPay: "Zu zahlender Betrag:",
    gasFee: "Gasgebühr:",
    totalAmount: "Gesamtbetrag:",
    proceedToPayment: "Weiter zur Zahlung",
    minimum1000: "Sie müssen mindestens 1000 MFT kaufen.",
    walletNotFound: "Wallet nicht gefunden. Bitte installieren Sie MetaMask.",
    walletConnectionError: "Fehler beim Verbinden der Wallet. Bitte versuchen Sie es erneut.",
    selectAccount: "Konto auswählen",
    account: "Konto",
    continue: "Weiter",
    selectAccountDescription: "Bitte wählen Sie Ihr Konto aus, um fortzufahren",

  }
};

const mockPrices = {
  binancecoin: { usd: 300 },
  ethereum: { usd: 2000 }
};

const getBrowserLanguage = () => {
  const language = navigator.language || navigator.userLanguage;
  if (language.startsWith('tr')) return 'tr';
  if (language.startsWith('de')) return 'de';
  return 'en';
};

const language = getBrowserLanguage();
const t = translations[language];

const WalletStep = ({ nextStep, setConnected, setAccount }) => {
  const [walletConnected, setWalletConnected] = useState(false);
  const [error, setError] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    checkWalletConnection();
  }, []);

  const checkWalletConnection = async () => {
    if (typeof window.ethereum !== 'undefined') {
      const web3 = new Web3(window.ethereum);
      try {
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setWalletConnected(true);
          setAccounts(accounts.map((account, index) => ({
            value: account,
            label: `${t.account} ${index + 1}: ${account.slice(0, 6)}...${account.slice(-4)}`
          })));
        }
      } catch (error) {
        console.error("Error checking wallet connection:", error);
      }
    }
  };



  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setAccounts(accounts.map((account, index) => ({
            value: account,
            label: `${t.account} ${index + 1}: ${account.slice(0, 6)}...${account.slice(-4)}`
          })));
          setWalletConnected(true);
          setError('');
        } else {
          setError(t.walletNotFound);
        }
      } catch (error) {
        console.error("Wallet connection error:", error);
        setError(t.walletConnectionError);
      }
    } else {
      setError(t.walletNotFound);
    }
  };

  const handleAccountSelection = (selectedOption) => {
    setSelectedAccount(selectedOption);
    setAccount(selectedOption.value);
    setConnected(true);
  };

  return (
    <div className="step-container">
      <h1 className="main-title">{t.welcome}</h1>
      {!walletConnected ? (
        <>
          <p className="description">{t.connectWalletDescription}</p>
          <button onClick={connectWallet} className="connect-wallet-button">
            {t.connectWallet}
          </button>
        </>
      ) : (
        <>
          <p className="description">{t.selectAccountDescription}</p>
          <div className="wallet-connected">
            <Select
              options={accounts}
              value={selectedAccount}
              onChange={handleAccountSelection}
              placeholder={t.selectAccount}
              className="account-select"
              classNamePrefix="account-select"
            />
            <button 
              onClick={nextStep} 
              className="connect-wallet-button"
              disabled={!selectedAccount}
            >
              {t.continue}
            </button>
          </div>
        </>
      )}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

const PurchaseStep = ({ previousStep, nextStep, amount, setAmount, selectedToken, setSelectedToken, tokenEquivalent, setTokenEquivalent, mftToUsdRate, prices, setPrices, account }) => {
  const recipientAddress = "0x0A93b1B79d90B8c2b6C987012287A2e4fBeFEA4E";
  const contractAddress = "0x1523930EdC9255B33392288f43Fd3B63d7aed7Cb";
  const [isAmountValid, setIsAmountValid] = useState(false);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [gasEstimate, setGasEstimate] = useState(0);
  const [purchaseStatus, setPurchaseStatus] = useState('');

  useEffect(() => {
    setPrices(mockPrices);
    updateTokenEquivalent(amount, mockPrices);
  }, []);

  useEffect(() => {
    updateTokenEquivalent(amount, prices);
    const numAmount = parseFloat(amount.replace(/,/g, ''));
    // Token minimum limiti 
    setIsAmountValid(!isNaN(numAmount) && numAmount >= 50);
    estimateGas();
  }, [amount, selectedToken, prices]);


  const saveTransferToParseDB = async (walletId, mftAmount, bnbAmount) => {
    try {
      const Transfer = Parse.Object.extend("Transfer");
      const transfer = new Transfer();

      transfer.set("walletId", walletId);
      transfer.set("mftAmount", mftAmount);
      transfer.set("bnbAmount", bnbAmount);
      transfer.set("date", new Date());

      await transfer.save();
      console.log('Transfer saved to Parse DB successfully');
    } catch (error) {
      console.error('Error saving transfer to Parse DB:', error);
      // Bu noktada, kayıt başarısız olsa bile işleme devam edebilir veya kullanıcıya bilgi verebilirsiniz
    }
  };

  
  const updateTokenEquivalent = (newAmount, currentPrices) => {
    const numAmount = parseFloat(newAmount.replace(/,/g, ''));
    if (!isNaN(numAmount) && currentPrices && selectedToken) {
      const selectedTokenPrice = currentPrices[selectedToken === 'bnb' ? 'binancecoin' : 'ethereum']?.usd;
      if (selectedTokenPrice) {
        const equivalent = (numAmount * mftToUsdRate) / selectedTokenPrice;
        setTokenEquivalent(equivalent);
        setTotalPaymentAmount(equivalent);
      }
    }
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleAmountChange = (event) => {
    const newAmount = event.target.value.replace(/,/g, '');
    setAmount(formatNumber(newAmount));
    const numAmount = parseFloat(newAmount);
    setIsAmountValid(!isNaN(numAmount) && numAmount >= 1000);
  };

  const handleTokenSelection = (token) => {
    setSelectedToken(token);
  };

  const estimateGas = async () => {
    if (isAmountValid && window.ethereum && account) {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(MFTTokenAbi, contractAddress);
      try {
        const gasPrice = await web3.eth.getGasPrice();
        const gasEstimate = await contract.methods.transfer(account, Web3.utils.toWei(amount.replace(/,/g, ''), 'ether')).estimateGas({ from: recipientAddress });
        const gasCost = Web3.utils.fromWei((gasPrice * gasEstimate).toString(), 'ether');
        setGasEstimate(parseFloat(gasCost));
        setTotalPaymentAmount(tokenEquivalent + parseFloat(gasCost));
      } catch (error) {
        console.error("Gas estimate error:", error);
      }
    }
  };

  const addTokenToWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const tokenAddress = '0x1523930EdC9255B33392288f43Fd3B63d7aed7Cb';
        const tokenSymbol = 'MFT';
        const tokenDecimals = 18;
        const tokenImage = 'https://mftcoin.com/path/to/mft_logo.png';
  
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenImage,
            },
          },
        });
  
        if (wasAdded) {
          console.log('MFT Token successfully added to wallet');
        } else {
          console.log('MFT Token was not added to wallet');
        }
      } catch (error) {
        console.error('Error adding token to wallet:', error);
        alert('Failed to add MFT token to your wallet. You can add it manually.');
      }
    }
  };


  const handleBuyTokens = async () => {
    try {
      console.log("Starting purchase process...");
      setPurchaseStatus('Initiating transaction...');
      const web3 = new Web3(window.ethereum);
  
      console.log("Calculating amounts...");
      const weiAmount = web3.utils.toWei(totalPaymentAmount.toString(), 'ether');
      const mftAmount = web3.utils.toWei(amount.replace(/,/g, ''), 'ether');
  
      console.log("Total payment amount (BNB):", totalPaymentAmount);
      console.log("MFT amount:", amount);
      console.log("Wei amount:", weiAmount);
  
      console.log("Transferring payment...");
      setPurchaseStatus('Transferring BNB payment...');
      let paymentResult;
      try {
        paymentResult = await web3.eth.sendTransaction({
          from: account,
          to: recipientAddress,
          value: weiAmount
        });
        console.log("Payment result:", paymentResult);

        await saveTransferToParseDB(account, amount, totalPaymentAmount);

      } catch (txError) {
        console.error("Transaction error:", txError);
        throw new Error(`BNB transfer failed: ${txError.message}. Please check your wallet and try again.`);
      }
  
      console.log("Calling API to process payment...");
      setPurchaseStatus('Processing payment and transferring MFT tokens...');
      let apiResponse;
      try {
        apiResponse = await fetch('https://www.myfacecoin.net/api/sendmfttokens', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            account,
            amount: mftAmount,
            paymentTxHash: paymentResult.transactionHash
          })
        });
      } catch (fetchError) {
        console.error("Fetch error:", fetchError);
        throw new Error(`Failed to communicate with the server: ${fetchError.message}. Please try again later.`);
      }
  
      console.log("API response:", apiResponse);
  
      if (!apiResponse.ok) {
        const errorText = await apiResponse.text();
        console.error("API response error text:", errorText);
        throw new Error(`API response error: ${errorText}`);
      }
  
      const apiResult = await apiResponse.json();
      console.log("API result JSON:", apiResult);
  
      if (!apiResult.success) {
        console.error("API error response:", apiResult);
        throw new Error(apiResult.error || 'Unknown server error');
      }
  
      console.log("API result:", apiResult);
  
      if (apiResult.success) {
        console.log("Transaction complete!");
        setPurchaseStatus('Transaction complete!');
        await addTokenToWallet();
        alert(t.purchaseSuccessful);
        nextStep();
      } else {
        throw new Error(apiResult.message || "Token transfer failed for an unknown reason");
      }
    } catch (error) {
      console.error("Token purchase failed:", error);
      setPurchaseStatus('Transaction failed. Please try again.');
      alert(`Error: ${error.message}`);
    }
  };  
  
  return (
    <div className="step-container">
      <h1 className="main-title">{t.buyMFT}</h1>
      <div className="crypto-payment">
        <p className="amount-label">{t.howManyMFT}</p>
        <div className="amount-input-container">
          <input
            type="text"
            className={`amount-input ${isAmountValid ? 'valid' : 'invalid'}`}
            value={amount}
            onChange={handleAmountChange}
            placeholder="0"
          />
        </div>
        {!isAmountValid && <p className="error-message">{t.minimum1000}</p>}
        <div className="payment-method-buttons">
          <button
            className={`payment-button ${selectedToken === 'bnb' ? 'active' : ''} ${isAmountValid ? '' : 'disabled'}`}
            onClick={() => isAmountValid && handleTokenSelection('bnb')}
            disabled={!isAmountValid}
          >
            BNB
          </button>
          <button
            className={`payment-button ${selectedToken === 'eth' ? 'active' : ''} ${isAmountValid ? '' : 'disabled'}`}
            onClick={() => isAmountValid && handleTokenSelection('eth')}
            disabled={!isAmountValid}
          >
            ETH
          </button>
        </div>

        {isAmountValid && selectedToken && (
          <>
            <p className="token-equivalent">{t.equivalent} {tokenEquivalent.toFixed(6)} {selectedToken.toUpperCase()}</p>
            <p className="payment-details">{t.amountToPay} {tokenEquivalent.toFixed(6)} {selectedToken.toUpperCase()}</p>
            <p className="payment-details">{t.gasFee} {gasEstimate.toFixed(6)} {selectedToken.toUpperCase()}</p>
            <p className="payment-details total-amount">{t.totalAmount} <strong>{totalPaymentAmount.toFixed(6)} {selectedToken.toUpperCase()}</strong></p>
            <button onClick={handleBuyTokens} className="buy-tokens-button">
              {t.proceedToPayment}
            </button>
          </>
        )}
      </div>
      <div className="step-buttons">
        <button onClick={previousStep} className="previous-step-button">{t.back}</button>
      </div>
      {purchaseStatus && <p className="purchase-status">{purchaseStatus}</p>}
    </div>
  );
};

const ConfirmationStep = ({ previousStep }) => {
  return (
    <div className="step-container">
      <h1 className="main-title">{t.transactionComplete}</h1>
      <p className="description">{t.purchaseSuccessful}</p>
      <button onClick={previousStep} className="previous-step-button">{t.back}</button>
    </div>
  );
};

const BuyTokenWizard = ({ onClose }) => {
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState('');
  const [amount, setAmount] = useState('');
  const [tokenEquivalent, setTokenEquivalent] = useState(0);
  const [selectedToken, setSelectedToken] = useState('');
  const [prices, setPrices] = useState(mockPrices);
  const mftToUsdRate = 0.005;  // 1 MFT = $0.0025 (çeyrek cent)

  const handleContainerClick = (e) => {
    if (e.target.className === 'buy-token-container') {
      onClose();
    }
  };

  return (
    <div className="buy-token-container" onClick={handleContainerClick}>
      <div className="buy-token-card">
        <StepWizard>
          <WalletStep 
            nextStep={() => {}} 
            setConnected={setConnected} 
            setAccount={setAccount} 
          />
          <PurchaseStep
            previousStep={() => {}}
            nextStep={() => {}}
            amount={amount}
            setAmount={setAmount}
            selectedToken={selectedToken}
            setSelectedToken={setSelectedToken}
            tokenEquivalent={tokenEquivalent}
            setTokenEquivalent={setTokenEquivalent}
            mftToUsdRate={mftToUsdRate}
            prices={prices}
            setPrices={setPrices}
            account={account}
          />
          <ConfirmationStep previousStep={() => {}} />
        </StepWizard>
      </div>
    </div>
  );
};

export default BuyTokenWizard;
