import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { scrollToTop } from "../../lib/helpers";

// Sözleşme metinlerini değişken olarak tanımlayın
const termsText = `
    Datenschutzrichtlinie
Letzte Aktualisierung: 24. Februar 2024

myfuturetoken, zugänglich unter: https://myfuturetoken.com, eine seiner Hauptprioritäten ist die Privatsphäre der Teilnehmer, die Besucher von https://myfuturetoken.com und seinen dApps (die „Schnittstelle“) sind. myfuturetoken ist bestrebt, so wenig personenbezogene Daten wie möglich zu sammeln. Dieses Datenschutzrichtliniendokument enthält Arten von Daten, die von myfuturetoken erfasst, verwendet und aufgezeichnet werden.

Die von myfuturetoken Limited unterstützte myfuturetoken-Schnittstelle mit Sitz in: 79 King William St, London EC3N 7BG, Vereinigtes Königreich, die im Rahmen dieser Datenschutzrichtlinie der Verantwortliche für Ihre personenbezogenen Daten ist. myfuturetoken entscheidet, „warum“ und „wie“ Ihre personenbezogenen Daten im Zusammenhang mit der Schnittstelle verarbeitet werden. Wenn Sie weitere Fragen haben oder weitere Informationen zu dieser Datenschutzrichtlinie benötigen, wenden Sie sich bitte an info@myfuturetoken.com.

Diese Datenschutzrichtlinie gilt nur für die Schnittstellenaktivitäten und gilt für Teilnehmer, die Besucher der Schnittstelle sind, in Bezug auf die personenbezogenen Daten, die sie weitergeben und/oder die innerhalb der myfuturetoken Schnittstelle erfasst werden. Diese Datenschutzrichtlinie gilt nicht für personenbezogene Daten, die offline oder über andere Kanäle als die Schnittstelle erfasst werden. Bitte lesen Sie diese Datenschutzrichtlinie sorgfältig durch, um unsere Richtlinien und Praktiken in Bezug auf Ihre Daten und deren Behandlung durch die Schnittstelle zu verstehen.

WENN SIE NICHT DAS RECHT, DIE BEFUGNIS UND DIE AUTORITÄT HABEN, IM NAMEN DES UNTERNEHMENS, DER ORGANISATION ODER DER ANDEREN JURISTISCHEN ORGANISATION, DIE SIE VERTRETEN, ZU HANDELN UND DIESE BINDLICHKEIT ZU HANDELN, DÜRFEN SIE BITTE NICHT AUF DIE SCHNITTSTELLE ZUGREIFEN ODER SIE ANDERWEITIG VERWENDEN. Wenn Sie daran interessiert sind, wie wir Cookies verwenden, und Sie Ihre Cookie-Auswahl ändern können, lesen Sie bitte Abschnitt 5 „Cookies und automatisch erfasste Daten“.

1. Änderungen dieser Vereinbarung
Wenn sich unsere Datenverarbeitungspraktiken ändern, werden wir diese Datenschutzrichtlinie entsprechend aktualisieren, um Sie im Voraus darüber zu informieren und Ihnen die Möglichkeit zu geben, entweder Ihre Einwilligung zu erteilen, einer bestimmten Verarbeitung zu widersprechen oder andere Maßnahmen zu ergreifen, auf die Sie gemäß der Verordnung Anspruch haben. Bitte verfolgen Sie alle Änderungen, die wir an dieser Datenschutzrichtlinie vornehmen. Durch Ihren fortgesetzten Zugriff auf die Schnittstelle und deren Nutzung erklären Sie sich mit allen Änderungen vertraut, die an dieser Datenschutzrichtlinie zum Zeitpunkt Ihres Zugriffs auf die Schnittstelle und Ihrer Nutzung der Schnittstelle vorgenommen wurden. Daher empfehlen wir Ihnen, diese Datenschutzrichtlinie regelmäßig zu überprüfen, da Sie an sie gebunden sind. Sollten Sie aus irgendeinem Grund mit unseren Praktiken zur Verarbeitung personenbezogener Daten nicht zufrieden sein, besteht Ihr unmittelbarer Rechtsbehelf darin, die Nutzung der Schnittstelle einzustellen. Sie müssen uns über diese Entscheidung nicht informieren, es sei denn, Sie beabsichtigen, einige der in der DSGVO vorgesehenen und nachfolgend in dieser Datenschutzerklärung definierten Datenschutzrechte auszuüben.

2. Teilnahmeberechtigung
Alter. Indem Sie über die Schnittstelle auf uns zugreifen, erklären und gewährleisten Sie, dass Sie mindestens achtzehn (18) Jahre alt sind. Wenn Sie unter achtzehn (18) Jahre alt sind, dürfen Sie die Schnittstelle unter keinen Umständen und aus keinem Grund nutzen. Bitte melden Sie uns alle Vorfälle, bei denen Personen unter 18 Jahren die Schnittstelle nutzen, sofern Ihnen diese bekannt werden.

3. Anwendbarkeit
Diese Datenschutzrichtlinie gilt für alle Ihre Interaktionen mit uns über die Schnittstelle und Ihre Interaktionen mit uns im Zusammenhang damit.

Im Folgenden finden Sie die Kategorien unserer Auftragsverarbeiter, die wir auf der Schnittstelle verwenden. Dies erfolgt aufgrund einer internen Datenverarbeitungs-Roadmap, die einen kurzen Überblick über unsere Datenverarbeitungsaktivitäten in Bezug auf alle personenbezogenen Daten gibt, die wir möglicherweise über die Schnittstelle erfassen, sowie über Ihren Platz darin Datenverarbeitungsereignis. Es kann unter info@myfuturetoken.com angefordert werden. Nachfolgend sind die Kategorien unserer Auftragsverarbeiter aufgeführt, die über die Schnittstelle auf Ihre personenbezogenen Daten zugreifen und diese verarbeiten können:

Anbieter von technischer Wartung;
Anbieter von Projekt- und Teammanagement;
Kommunikationsanbieter;
Analysen, Statistiken, Leistung, Marketinganbieter.
4. Datenverarbeitung im Zusammenhang mit der Schnittstelle
Arten der erfassten Daten

Im größtmöglichen Umfang versucht myfuturetoken, so wenig personenbezogene Daten wie möglich von Ihnen zu sammeln. Von uns erfasste personenbezogene Daten:

IP-Adresse, MAC-Adresse, Protokolldateien, Domänenserver, Daten im Zusammenhang mit Nutzung, Leistung, Website-Sicherheit, Verkehrsmuster, Standortinformationen, Browser- und Geräteinformationen – nur, wenn Sie die Schnittstelle nutzen;
Wallet-Adressen (öffentliche Blockchain-Adressen), Transaktions- und Kontostandinformationen (Blockchain-Daten), auf die bei der Interaktion mit der Schnittstelle zugegriffen werden kann; Wir verwenden öffentliche Blockchain-Adressen, um die Reise eines Benutzers durch unser Produkt zu identifizieren. Wir gruppieren und analysieren diese Benutzerreisen gemeinsam, um die Benutzererfahrung unserer Produkte zu verbessern. Wir verwenden diese Daten für keinerlei Zwecke auf der Ebene einzelner Benutzer. Rechtsgrundlage für diese Verarbeitung sind unsere berechtigten Interessen, wie z. B. die Überwachung und Verbesserung der Schnittstelle, der ordnungsgemäße Schutz der Schnittstelle vor Risiken und teilweise die Vertragserfüllungsgrundlage zur Bereitstellung der Schnittstelle für Sie. Beachten Sie, dass wir nicht für Ihre Nutzung der Blockchain und Ihre in diesen dezentralen und erlaubnisfreien Netzwerken verarbeiteten Daten verantwortlich sind.
Protokolldateien. myfuturetoken folgt einem Standardverfahren zur Verwendung von Protokolldateien. Diese Dateien protokollieren Besucher, wenn sie Websites besuchen. Alle Hosting-Unternehmen tun dies und diese Art personenbezogener Daten kann auch im Rahmen der Analyse von Hosting-Diensten erfasst werden. Zu den von Protokolldateien erfassten Daten gehören Internetprotokolladressen (IP), Browsertyp, Internetdienstanbieter (ISP), Datums- und Zeitstempel, Verweis-/Ausstiegsseiten und möglicherweise die Anzahl der Klicks. Diese Daten können mit personenbezogenen Daten verknüpft werden. Der Zweck der Datenerhebung und -verarbeitung besteht darin, Trends zu analysieren, die Website zu verwalten, die Bewegungen der Benutzer auf der Website zu verfolgen und demografische Informationen zu sammeln.
myfuturetoken kann auch Werbeplattformen Dritter beauftragen, die nur ausgelöst werden, wenn ihre technischen Funktionen (sogenannte „Pixel“) über die Schnittstelle aktiviert werden. Die genannten Werbeplattformen Dritter dürfen personenbezogene Daten der Besucher von Interface nur zu dem Zweck erfassen, ihre Werbemöglichkeiten über ihre Plattformen zu optimieren, Sie mit ihren Anzeigen gezielt anzusprechen und Ihre Daten möglicherweise zur weiteren Verwendung an andere Werbeplattformen und Agenturen weiterzugeben. myfuturetoken kann mit den genannten personenbezogenen Daten der Besucher von Interfaces interagieren.

In keinem Fall werden wir Sie bitten, Ihre privaten Schlüssel oder Ihren Wallet-Seed mitzuteilen. Vertrauen Sie niemals jemandem oder einer Website, die Sie zur Eingabe Ihrer privaten Schlüssel oder Ihres Wallet-Seeds auffordert.

Wie und warum wir Ihre personenbezogenen Daten verwenden

Wir dürfen Ihre oben aufgeführten personenbezogenen Daten nur für Folgendes verwenden:

Unsere internen und betrieblichen Zwecke: Gewährleistung der Sicherheit, Erkennung unregelmäßigen Website-Verhaltens, Verhinderung betrügerischer Aktivitäten und Verbesserung der Sicherheit auf allen möglichen Ebenen;
Bewertung und Verbesserung der Leistung der Schnittstelle;
Analyse der Aktionen unserer Website-Besucher zur Verbesserung unserer Benutzeroberfläche (Abschnitt „Cookies und automatisch erfasste Daten“);
Analyse des Schnittstellenverhaltens, unter anderem über: Google Analytics (weitere Informationen finden Sie in der Analytics-Richtlinie von Google);
Betrug erkennen und verhindern.
Um etwaige Zweifel auszuräumen, können wir die oben beschriebenen personenbezogenen Daten oder andere personenbezogene Daten verwenden:

auf der Grundlage der Vertragserfüllung oder der Notwendigkeit, einen Vertrag abzuschließen (wobei die personenbezogenen Daten erforderlich sind, damit wir unsere Verpflichtungen und Verpflichtungen gemäß einem Vertrag erfüllen können, den wir eingehen, wenn Sie unsere Dienste nutzen, oder wenn wir uns in Verhandlungen befinden). Phase);
auf der Grundlage unserer berechtigten Interessen oder der unserer Auftragsverarbeiter, die Schnittstelle zu schützen, böswillige und schädliche Aktivitäten an der Schnittstelle zu verhindern, unsere technischen Systeme funktionsfähig und sicher zu halten, Dienstleistungen und Produkte durch die Verwendung aggregierter Statistiken zu verbessern;
um auf rechtliche Anfragen von Behörden zu reagieren, Informationen zu Gerichtsbeschlüssen und Urteilen bereitzustellen oder wenn wir in gutem Glauben davon ausgehen, dass eine solche Offenlegung erforderlich ist, um behördlichen Ermittlungen oder Gerichtsverfahren nachzukommen, die von Regierungs- und/oder Strafverfolgungsbeamten eingeleitet werden, oder private Parteien, einschließlich, aber nicht beschränkt auf: als Reaktion auf Vorladungen, Durchsuchungsbefehle oder Gerichtsbeschlüsse und einschließlich anderer ähnlicher gesetzlicher Verpflichtungen, denen wir oder unsere Auftragsverarbeiter unterliegen;
auf Grundlage Ihrer Einwilligung; Und
auf anderen Rechtsgrundlagen, die in den Gesetzen zum Schutz personenbezogener Daten festgelegt sind.
Offenlegung von Daten

In Fortsetzung der Rechtsgrundlagen für die Erhebung und Verarbeitung personenbezogener Daten können wir personenbezogene Daten über Sie offenlegen:

im Zusammenhang mit einer Fusion, Teilung, Umstrukturierung oder einer anderen Verbandsänderung; oder
an unsere Tochtergesellschaften oder verbundenen Unternehmen (falls vorhanden) nur, wenn dies für betriebliche Zwecke erforderlich ist. Wenn wir Ihre personenbezogenen Daten offenlegen müssen, um behördlichen Untersuchungen oder Gerichtsverfahren nachzukommen, die von Regierungs- und/oder Strafverfolgungsbeamten eingeleitet werden, können wir möglicherweise nicht sicherstellen, dass diese Empfänger Ihrer personenbezogenen Daten die Privatsphäre oder Sicherheit von wahren Ihre persönlichen Daten.
Datenaufbewahrungsfrist

myfuturetoken speichert personenbezogene Daten ausschließlich für den Zeitraum, der zur Erfüllung der hierin vorgeschriebenen rechtlichen Zwecke erforderlich ist. Wenn wir personenbezogene Daten nicht mehr benötigen, die Verjährungsfrist für die Speicherung dieser personenbezogenen Daten abgelaufen ist, Sie Ihre Einwilligung widerrufen oder unseren berechtigten Interessen oder denen unserer Auftragsverarbeiter widersprochen haben, löschen oder vernichten wir sie sicher, es sei denn, wir, unsere Auftragsverarbeiter, erfüllen die gesetzlichen Anforderungen oder andere Verantwortliche unterliegen einer abweichenden Regelung. Aggregierte Daten, die ein Gerät/einen Browser (oder eine Einzelperson) nicht direkt identifizieren können und zu Berichts- und Analysezwecken verwendet werden, werden so lange wie wirtschaftlich erforderlich aufbewahrt, bis Sie der Verarbeitung dieser Daten widersprechen oder Ihre Einwilligung widerrufen.

Manchmal sind wir aufgrund gesetzlicher Vorschriften dazu verpflichtet, bestimmte Daten für bestimmte Zwecke über einen längeren Zeitraum aufzubewahren. Gründe dafür, dass wir einige Daten möglicherweise über einen längeren Zeitraum aufbewahren, sind unter anderem:

Sicherheit, Betrugs- und Missbrauchsprävention;
Finanzüberwachung und Führung von Aufzeichnungen;
Einhaltung gesetzlicher oder behördlicher Anforderungen;
Sicherstellung der Kontinuität Ihrer Interaktion mit der Schnittstelle.
Ihre Anfragen

Sie können uns per E-Mail unter der folgenden E-Mail-Adresse kontaktieren: info@myfuturetoken.com; Die Daten, die Sie uns in einer E-Mail mitteilen und die Sie uns freiwillig mitteilen, verwenden wir nur, um Ihre Frage zu beantworten bzw. Ihre E-Mail bestmöglich zu beantworten.

5. Cookies und automatisch erfasste Daten
Während Sie durch unsere Benutzeroberfläche navigieren und mit ihr interagieren, bitten wir Sie möglicherweise um Ihre Zustimmung zur Verwendung von Cookies, das sind kleine Dateien, die auf der Festplatte/dem Browser Ihres Computers oder Mobilgeräts abgelegt werden, und Web-Beacons, das sind kleine elektronische Dateien, die sich auf den Seiten von befinden die Schnittstelle, um bestimmte Informationen über die von Ihnen verwendeten Geräte, Browsing-Aktionen und -Muster zu sammeln.

Die von Cookies und Web-Beacons automatisch erfassten Daten können Informationen über Ihren Webbrowser (z. B. Browsertyp und Browsersprache) und Details zu Ihren Besuchen auf der Schnittstelle umfassen, einschließlich Verkehrsdaten, Standortdaten und -protokolle, Seitenaufrufe, Besuchsdauer, und Website-Navigationspfade sowie Informationen über Ihr Gerät und Ihre Internetverbindung, einschließlich Ihrer IP-Adresse und wie Sie mit der Schnittstelle interagieren. Wir erfassen diese Daten, um die Schnittstelle und die Interaktion damit zu verbessern.

Zu den von uns automatisch erfassten Informationen können auch statistische und Leistungsinformationen gehören, die sich aus Ihrer Nutzung der Schnittstelle ergeben. Diese Art von Daten werden von uns ausschließlich aggregiert und pseudonymisiert verwendet.

Sie können Cookies über Ihre individuellen Browseroptionen deaktivieren. Ausführlichere Informationen zur Cookie-Verwaltung mit bestimmten Webbrowsern finden Sie auf den jeweiligen Websites der Browser:

Für den Google Chrome-Browser lesen Sie bitte diese Anweisungen: https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en;
Informationen zum Firefox-Browser finden Sie hier: https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox
Für den Safari-Browser besuchen Sie bitte: https://support.apple.com/ru-ru/guide/safari/sfri11471/mac
Informationen zum Internet Explorer-Browser finden Sie unter: https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
6. Ihre Rechte gemäß DSGVO
Unter bestimmten Umständen haben Sie möglicherweise eine Reihe von Datenschutzrechten hinsichtlich der Nutzung, Speicherung und Verarbeitung Ihrer personenbezogenen Daten (z. B. das Recht, Ihre Daten zu löschen). Hier ist eine Liste der Datenschutzrechte:

Recht auf Information – wir veröffentlichen diese Datenschutzrichtlinie, um Sie darüber zu informieren, was wir mit Ihren personenbezogenen Daten tun. Sie können uns jederzeit nach personenbezogenen Daten über Sie fragen, die wir speichern. Diese Informationen betreffen unter anderem die von uns verarbeiteten Datenkategorien, zu welchen Zwecken wir sie verarbeiten, die Herkunft der Daten, sofern wir diese nicht direkt bei Ihnen erhoben haben und ggf. die Empfänger, an die wir Ihre Daten übermittelt haben.
Zugriffsrecht – Sie können uns fragen, ob wir Ihre personenbezogenen Daten verarbeiten, und Sie haben das Recht, eine Kopie der Daten anzufordern, die wir über Sie gespeichert haben.
Recht auf Berichtigung – Sie haben das Recht, unrichtige oder unvollständige Daten über Sie zu berichtigen.
Recht auf Vergessenwerden – Sie können verlangen, dass die personenbezogenen Daten, die wir über Sie gespeichert haben, aus unserem System gelöscht werden. Wir werden dieser Aufforderung nachkommen, es sei denn, wir haben einen legitimen Grund, eine gesetzliche Verpflichtung oder eine andere gesetzliche Grundlage, dies nicht zu tun. Selbst wenn wir die personenbezogenen Daten, die Gegenstand unserer aktiven (laufenden) Verarbeitungstätigkeiten sind, löschen (löschen) und ihre Verarbeitung einstellen können, werden wir diese bestimmten personenbezogenen Daten dennoch in unseren Sicherungs- und Archivspeichern aufbewahren, um unsere gesetzlichen und sonstigen Anforderungen zu erfüllen.
Recht auf Einschränkung der Verarbeitung – unter bestimmten Bedingungen können Sie uns bitten, die Verarbeitung Ihrer personenbezogenen Daten zu „blockieren“.
Recht auf Datenübertragbarkeit – Sie haben das Recht, die von uns über Sie gespeicherten Daten an eine andere Organisation übertragen zu lassen und personenbezogene Daten in einem strukturierten, allgemein verwendeten Format zu erhalten. Bitte wenden Sie sich an: info@myfuturetoken.com, um herauszufinden, ob wir derzeit die Bereitstellung der übertragbaren Datei mit den von uns über Sie verarbeiteten personenbezogenen Daten unterstützen.
Widerspruchsrecht – Sie können der Verarbeitung Ihrer Daten jederzeit aus Gründen, die sich aus Ihrer besonderen Situation ergeben, unter info@myfuturetoken.com widersprechen, sofern die Datenverarbeitung auf unserem berechtigten Interesse oder dem eines Dritten beruht. oder wenn wir Profiling durchführen, maschinelles Lernen oder automatisierte Entscheidungsalgorithmen verwenden. In diesem Fall werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten. Letzteres gilt nicht, sofern wir zwingende schutzwürdige Gründe für die Verarbeitung nachweisen können, die Ihre Interessen überwiegen, oder wir Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen.
Recht auf Widerruf der Einwilligung – Widerrufen Sie die Einwilligung, die Sie uns in Bezug auf die Verarbeitung Ihrer personenbezogenen Daten für bestimmte Zwecke erteilt haben.
Recht auf Beschwerde – wir nehmen Ihre Rechte sehr ernst. Sollten Sie jedoch der Meinung sein, dass wir Ihre Beschwerden nicht ausreichend bearbeitet haben, haben Sie das Recht, eine Beschwerde bei der zuständigen Datenschutzbehörde einzureichen. Sie können Ihre Beschwerden an die EWR-Aufsichtsbehörde Ihres Wohnsitzlandes richten.
Bei Fragen zur Ausübung der oben genannten Rechte senden Sie bitte eine E-Mail an info@myfuturetoken.com. Wenn Sie mehr über die DSGVO und Ihre Rechte erfahren möchten, ist die Website des Information Commissioner’s Office eine zuverlässige Quelle.

7. Privatsphäre von Kindern
Unsere Schnittstelle ist nicht dazu bestimmt, Daten von Personen unter 18 Jahren zu sammeln. Wir gestatten wissentlich niemandem unter 18 Jahren, Daten an unsere Schnittstelle zu übermitteln. Wenn Sie glauben, dass Ihr Kind uns möglicherweise seine Daten zur Verfügung gestellt hat, können Sie uns über die Informationen in dieser Richtlinie kontaktieren und wir werden die Daten von unserer Schnittstelle löschen.

8. Übermittlung personenbezogener Daten
Übermittlungen in Drittländer unterliegen geeigneten Garantien, insbesondere Standardvertragsklauseln, die von der Aufsichtsbehörde angenommen und von der Kommission genehmigt wurden. Auf vorherige schriftliche Anfrage können Sie eine Kopie der oben genannten geeigneten Sicherheitsvorkehrungen erhalten. Wir können Sie über weitere Schritte informieren, die zum Erhalt einer solchen Kopie erforderlich sind, einschließlich Ihrer Verpflichtung, Vertraulichkeitsverpflichtungen im Zusammenhang mit der Offenlegung der geschützten und persönlichen myfuturetoken-Informationen Dritter sowie der Bedingungen ihrer Beziehungen zu myfuturetoken einzugehen.

Beachten Sie, dass die Verwendung der auf öffentlichen Blockchains basierenden Schnittstelle darauf abzielt, Transaktionen über große Netzwerke von Computersystemen hinweg unveränderlich aufzuzeichnen. Viele Blockchains sind offen für forensische Analysen, die zur Deanonymisierung und zur unbeabsichtigten Offenlegung personenbezogener Daten führen können, insbesondere wenn Blockchain-Daten mit anderen Daten kombiniert werden. Da es sich bei Blockchains um dezentralisierte Netzwerke Dritter handelt, die nicht von uns kontrolliert oder betrieben werden, sind wir nicht in der Lage, personenbezogene Daten aus solchen Netzwerken zu löschen, zu ändern oder zu verändern.

9. Datenintegrität und Sicherheit der Verarbeitung
Wir nehmen den Datenschutz sehr ernst. Wir arbeiten hart daran, die personenbezogenen Daten, die Sie uns zur Verfügung stellen, vor Verlust, Missbrauch oder unbefugtem Zugriff zu schützen. Wir nutzen eine Vielzahl von Schutzmaßnahmen wie Verschlüsselung, digitale und physische Zugangskontrollen, Geheimhaltungsvereinbarungen und andere technische und organisatorische Maßnahmen, um die uns übermittelten personenbezogenen Daten sowohl während der Übertragung als auch im Ruhezustand zu schützen.

Bitte beachten Sie, dass die elektronische Übertragung, Speicherung oder Verarbeitung personenbezogener Daten nicht völlig sicher sein kann. Wir können nicht garantieren, dass die von uns zum Schutz personenbezogener Daten getroffenen Sicherheitsmaßnahmen niemals außer Kraft gesetzt werden oder versagen oder dass diese Maßnahmen immer ausreichend oder wirksam sind. Obwohl wir uns zum Schutz Ihrer Privatsphäre verpflichten, versprechen wir nichts und Sie sollten nicht erwarten, dass Ihre personenbezogenen Daten immer privat oder sicher bleiben.

`;

const privacyText = `
  Datenschutzrichtlinie
Letzte Aktualisierung: 24. Februar 2024

myfuturetoken, zugänglich unter: https://myfuturetoken.com, eine seiner Hauptprioritäten ist die Privatsphäre der Teilnehmer, die Besucher von https://myfuturetoken.com und seinen dApps (die „Schnittstelle“) sind. myfuturetoken ist bestrebt, so wenig personenbezogene Daten wie möglich zu sammeln. Dieses Datenschutzrichtliniendokument enthält Arten von Daten, die von myfuturetoken erfasst, verwendet und aufgezeichnet werden.

Die von myfuturetoken Limited unterstützte myfuturetoken-Schnittstelle mit Sitz in: 79 King William St, London EC3N 7BG, Vereinigtes Königreich, die im Rahmen dieser Datenschutzrichtlinie der Verantwortliche für Ihre personenbezogenen Daten ist. myfuturetoken entscheidet, „warum“ und „wie“ Ihre personenbezogenen Daten im Zusammenhang mit der Schnittstelle verarbeitet werden. Wenn Sie weitere Fragen haben oder weitere Informationen zu dieser Datenschutzrichtlinie benötigen, wenden Sie sich bitte an info@myfuturetoken.com.

Diese Datenschutzrichtlinie gilt nur für die Schnittstellenaktivitäten und gilt für Teilnehmer, die Besucher der Schnittstelle sind, in Bezug auf die personenbezogenen Daten, die sie weitergeben und/oder die innerhalb der myfuturetoken Schnittstelle erfasst werden. Diese Datenschutzrichtlinie gilt nicht für personenbezogene Daten, die offline oder über andere Kanäle als die Schnittstelle erfasst werden. Bitte lesen Sie diese Datenschutzrichtlinie sorgfältig durch, um unsere Richtlinien und Praktiken in Bezug auf Ihre Daten und deren Behandlung durch die Schnittstelle zu verstehen.

WENN SIE NICHT DAS RECHT, DIE BEFUGNIS UND DIE AUTORITÄT HABEN, IM NAMEN DES UNTERNEHMENS, DER ORGANISATION ODER DER ANDEREN JURISTISCHEN ORGANISATION, DIE SIE VERTRETEN, ZU HANDELN UND DIESE BINDLICHKEIT ZU HANDELN, DÜRFEN SIE BITTE NICHT AUF DIE SCHNITTSTELLE ZUGREIFEN ODER SIE ANDERWEITIG VERWENDEN. Wenn Sie daran interessiert sind, wie wir Cookies verwenden, und Sie Ihre Cookie-Auswahl ändern können, lesen Sie bitte Abschnitt 5 „Cookies und automatisch erfasste Daten“.

1. Änderungen dieser Vereinbarung
Wenn sich unsere Datenverarbeitungspraktiken ändern, werden wir diese Datenschutzrichtlinie entsprechend aktualisieren, um Sie im Voraus darüber zu informieren und Ihnen die Möglichkeit zu geben, entweder Ihre Einwilligung zu erteilen, einer bestimmten Verarbeitung zu widersprechen oder andere Maßnahmen zu ergreifen, auf die Sie gemäß der Verordnung Anspruch haben. Bitte verfolgen Sie alle Änderungen, die wir an dieser Datenschutzrichtlinie vornehmen. Durch Ihren fortgesetzten Zugriff auf die Schnittstelle und deren Nutzung erklären Sie sich mit allen Änderungen vertraut, die an dieser Datenschutzrichtlinie zum Zeitpunkt Ihres Zugriffs auf die Schnittstelle und Ihrer Nutzung der Schnittstelle vorgenommen wurden. Daher empfehlen wir Ihnen, diese Datenschutzrichtlinie regelmäßig zu überprüfen, da Sie an sie gebunden sind. Sollten Sie aus irgendeinem Grund mit unseren Praktiken zur Verarbeitung personenbezogener Daten nicht zufrieden sein, besteht Ihr unmittelbarer Rechtsbehelf darin, die Nutzung der Schnittstelle einzustellen. Sie müssen uns über diese Entscheidung nicht informieren, es sei denn, Sie beabsichtigen, einige der in der DSGVO vorgesehenen und nachfolgend in dieser Datenschutzerklärung definierten Datenschutzrechte auszuüben.

2. Teilnahmeberechtigung
Alter. Indem Sie über die Schnittstelle auf uns zugreifen, erklären und gewährleisten Sie, dass Sie mindestens achtzehn (18) Jahre alt sind. Wenn Sie unter achtzehn (18) Jahre alt sind, dürfen Sie die Schnittstelle unter keinen Umständen und aus keinem Grund nutzen. Bitte melden Sie uns alle Vorfälle, bei denen Personen unter 18 Jahren die Schnittstelle nutzen, sofern Ihnen diese bekannt werden.

3. Anwendbarkeit
Diese Datenschutzrichtlinie gilt für alle Ihre Interaktionen mit uns über die Schnittstelle und Ihre Interaktionen mit uns im Zusammenhang damit.

Im Folgenden finden Sie die Kategorien unserer Auftragsverarbeiter, die wir auf der Schnittstelle verwenden. Dies erfolgt aufgrund einer internen Datenverarbeitungs-Roadmap, die einen kurzen Überblick über unsere Datenverarbeitungsaktivitäten in Bezug auf alle personenbezogenen Daten gibt, die wir möglicherweise über die Schnittstelle erfassen, sowie über Ihren Platz darin Datenverarbeitungsereignis. Es kann unter info@myfuturetoken.com angefordert werden. Nachfolgend sind die Kategorien unserer Auftragsverarbeiter aufgeführt, die über die Schnittstelle auf Ihre personenbezogenen Daten zugreifen und diese verarbeiten können:

Anbieter von technischer Wartung;
Anbieter von Projekt- und Teammanagement;
Kommunikationsanbieter;
Analysen, Statistiken, Leistung, Marketinganbieter.
4. Datenverarbeitung im Zusammenhang mit der Schnittstelle
Arten der erfassten Daten

Im größtmöglichen Umfang versucht myfuturetoken, so wenig personenbezogene Daten wie möglich von Ihnen zu sammeln. Von uns erfasste personenbezogene Daten:

IP-Adresse, MAC-Adresse, Protokolldateien, Domänenserver, Daten im Zusammenhang mit Nutzung, Leistung, Website-Sicherheit, Verkehrsmuster, Standortinformationen, Browser- und Geräteinformationen – nur, wenn Sie die Schnittstelle nutzen;
Wallet-Adressen (öffentliche Blockchain-Adressen), Transaktions- und Kontostandinformationen (Blockchain-Daten), auf die bei der Interaktion mit der Schnittstelle zugegriffen werden kann; Wir verwenden öffentliche Blockchain-Adressen, um die Reise eines Benutzers durch unser Produkt zu identifizieren. Wir gruppieren und analysieren diese Benutzerreisen gemeinsam, um die Benutzererfahrung unserer Produkte zu verbessern. Wir verwenden diese Daten für keinerlei Zwecke auf der Ebene einzelner Benutzer. Rechtsgrundlage für diese Verarbeitung sind unsere berechtigten Interessen, wie z. B. die Überwachung und Verbesserung der Schnittstelle, der ordnungsgemäße Schutz der Schnittstelle vor Risiken und teilweise die Vertragserfüllungsgrundlage zur Bereitstellung der Schnittstelle für Sie. Beachten Sie, dass wir nicht für Ihre Nutzung der Blockchain und Ihre in diesen dezentralen und erlaubnisfreien Netzwerken verarbeiteten Daten verantwortlich sind.
Protokolldateien. myfuturetoken folgt einem Standardverfahren zur Verwendung von Protokolldateien. Diese Dateien protokollieren Besucher, wenn sie Websites besuchen. Alle Hosting-Unternehmen tun dies und diese Art personenbezogener Daten kann auch im Rahmen der Analyse von Hosting-Diensten erfasst werden. Zu den von Protokolldateien erfassten Daten gehören Internetprotokolladressen (IP), Browsertyp, Internetdienstanbieter (ISP), Datums- und Zeitstempel, Verweis-/Ausstiegsseiten und möglicherweise die Anzahl der Klicks. Diese Daten können mit personenbezogenen Daten verknüpft werden. Der Zweck der Datenerhebung und -verarbeitung besteht darin, Trends zu analysieren, die Website zu verwalten, die Bewegungen der Benutzer auf der Website zu verfolgen und demografische Informationen zu sammeln.
myfuturetoken kann auch Werbeplattformen Dritter beauftragen, die nur ausgelöst werden, wenn ihre technischen Funktionen (sogenannte „Pixel“) über die Schnittstelle aktiviert werden. Die genannten Werbeplattformen Dritter dürfen personenbezogene Daten der Besucher von Interface nur zu dem Zweck erfassen, ihre Werbemöglichkeiten über ihre Plattformen zu optimieren, Sie mit ihren Anzeigen gezielt anzusprechen und Ihre Daten möglicherweise zur weiteren Verwendung an andere Werbeplattformen und Agenturen weiterzugeben. myfuturetoken kann mit den genannten personenbezogenen Daten der Besucher von Interfaces interagieren.

In keinem Fall werden wir Sie bitten, Ihre privaten Schlüssel oder Ihren Wallet-Seed mitzuteilen. Vertrauen Sie niemals jemandem oder einer Website, die Sie zur Eingabe Ihrer privaten Schlüssel oder Ihres Wallet-Seeds auffordert.

Wie und warum wir Ihre personenbezogenen Daten verwenden

Wir dürfen Ihre oben aufgeführten personenbezogenen Daten nur für Folgendes verwenden:

Unsere internen und betrieblichen Zwecke: Gewährleistung der Sicherheit, Erkennung unregelmäßigen Website-Verhaltens, Verhinderung betrügerischer Aktivitäten und Verbesserung der Sicherheit auf allen möglichen Ebenen;
Bewertung und Verbesserung der Leistung der Schnittstelle;
Analyse der Aktionen unserer Website-Besucher zur Verbesserung unserer Benutzeroberfläche (Abschnitt „Cookies und automatisch erfasste Daten“);
Analyse des Schnittstellenverhaltens, unter anderem über: Google Analytics (weitere Informationen finden Sie in der Analytics-Richtlinie von Google);
Betrug erkennen und verhindern.
Um etwaige Zweifel auszuräumen, können wir die oben beschriebenen personenbezogenen Daten oder andere personenbezogene Daten verwenden:

auf der Grundlage der Vertragserfüllung oder der Notwendigkeit, einen Vertrag abzuschließen (wobei die personenbezogenen Daten erforderlich sind, damit wir unsere Verpflichtungen und Verpflichtungen gemäß einem Vertrag erfüllen können, den wir eingehen, wenn Sie unsere Dienste nutzen, oder wenn wir uns in Verhandlungen befinden). Phase);
auf der Grundlage unserer berechtigten Interessen oder der unserer Auftragsverarbeiter, die Schnittstelle zu schützen, böswillige und schädliche Aktivitäten an der Schnittstelle zu verhindern, unsere technischen Systeme funktionsfähig und sicher zu halten, Dienstleistungen und Produkte durch die Verwendung aggregierter Statistiken zu verbessern;
um auf rechtliche Anfragen von Behörden zu reagieren, Informationen zu Gerichtsbeschlüssen und Urteilen bereitzustellen oder wenn wir in gutem Glauben davon ausgehen, dass eine solche Offenlegung erforderlich ist, um behördlichen Ermittlungen oder Gerichtsverfahren nachzukommen, die von Regierungs- und/oder Strafverfolgungsbeamten eingeleitet werden, oder private Parteien, einschließlich, aber nicht beschränkt auf: als Reaktion auf Vorladungen, Durchsuchungsbefehle oder Gerichtsbeschlüsse und einschließlich anderer ähnlicher gesetzlicher Verpflichtungen, denen wir oder unsere Auftragsverarbeiter unterliegen;
auf Grundlage Ihrer Einwilligung; Und
auf anderen Rechtsgrundlagen, die in den Gesetzen zum Schutz personenbezogener Daten festgelegt sind.
Offenlegung von Daten

In Fortsetzung der Rechtsgrundlagen für die Erhebung und Verarbeitung personenbezogener Daten können wir personenbezogene Daten über Sie offenlegen:

im Zusammenhang mit einer Fusion, Teilung, Umstrukturierung oder einer anderen Verbandsänderung; oder
an unsere Tochtergesellschaften oder verbundenen Unternehmen (falls vorhanden) nur, wenn dies für betriebliche Zwecke erforderlich ist. Wenn wir Ihre personenbezogenen Daten offenlegen müssen, um behördlichen Untersuchungen oder Gerichtsverfahren nachzukommen, die von Regierungs- und/oder Strafverfolgungsbeamten eingeleitet werden, können wir möglicherweise nicht sicherstellen, dass diese Empfänger Ihrer personenbezogenen Daten die Privatsphäre oder Sicherheit von wahren Ihre persönlichen Daten.
Datenaufbewahrungsfrist

myfuturetoken speichert personenbezogene Daten ausschließlich für den Zeitraum, der zur Erfüllung der hierin vorgeschriebenen rechtlichen Zwecke erforderlich ist. Wenn wir personenbezogene Daten nicht mehr benötigen, die Verjährungsfrist für die Speicherung dieser personenbezogenen Daten abgelaufen ist, Sie Ihre Einwilligung widerrufen oder unseren berechtigten Interessen oder denen unserer Auftragsverarbeiter widersprochen haben, löschen oder vernichten wir sie sicher, es sei denn, wir, unsere Auftragsverarbeiter, erfüllen die gesetzlichen Anforderungen oder andere Verantwortliche unterliegen einer abweichenden Regelung. Aggregierte Daten, die ein Gerät/einen Browser (oder eine Einzelperson) nicht direkt identifizieren können und zu Berichts- und Analysezwecken verwendet werden, werden so lange wie wirtschaftlich erforderlich aufbewahrt, bis Sie der Verarbeitung dieser Daten widersprechen oder Ihre Einwilligung widerrufen.

Manchmal sind wir aufgrund gesetzlicher Vorschriften dazu verpflichtet, bestimmte Daten für bestimmte Zwecke über einen längeren Zeitraum aufzubewahren. Gründe dafür, dass wir einige Daten möglicherweise über einen längeren Zeitraum aufbewahren, sind unter anderem:

Sicherheit, Betrugs- und Missbrauchsprävention;
Finanzüberwachung und Führung von Aufzeichnungen;
Einhaltung gesetzlicher oder behördlicher Anforderungen;
Sicherstellung der Kontinuität Ihrer Interaktion mit der Schnittstelle.
Ihre Anfragen

Sie können uns per E-Mail unter der folgenden E-Mail-Adresse kontaktieren: info@myfuturetoken.com; Die Daten, die Sie uns in einer E-Mail mitteilen und die Sie uns freiwillig mitteilen, verwenden wir nur, um Ihre Frage zu beantworten bzw. Ihre E-Mail bestmöglich zu beantworten.

5. Cookies und automatisch erfasste Daten
Während Sie durch unsere Benutzeroberfläche navigieren und mit ihr interagieren, bitten wir Sie möglicherweise um Ihre Zustimmung zur Verwendung von Cookies, das sind kleine Dateien, die auf der Festplatte/dem Browser Ihres Computers oder Mobilgeräts abgelegt werden, und Web-Beacons, das sind kleine elektronische Dateien, die sich auf den Seiten von befinden die Schnittstelle, um bestimmte Informationen über die von Ihnen verwendeten Geräte, Browsing-Aktionen und -Muster zu sammeln.

Die von Cookies und Web-Beacons automatisch erfassten Daten können Informationen über Ihren Webbrowser (z. B. Browsertyp und Browsersprache) und Details zu Ihren Besuchen auf der Schnittstelle umfassen, einschließlich Verkehrsdaten, Standortdaten und -protokolle, Seitenaufrufe, Besuchsdauer, und Website-Navigationspfade sowie Informationen über Ihr Gerät und Ihre Internetverbindung, einschließlich Ihrer IP-Adresse und wie Sie mit der Schnittstelle interagieren. Wir erfassen diese Daten, um die Schnittstelle und die Interaktion damit zu verbessern.

Zu den von uns automatisch erfassten Informationen können auch statistische und Leistungsinformationen gehören, die sich aus Ihrer Nutzung der Schnittstelle ergeben. Diese Art von Daten werden von uns ausschließlich aggregiert und pseudonymisiert verwendet.

Sie können Cookies über Ihre individuellen Browseroptionen deaktivieren. Ausführlichere Informationen zur Cookie-Verwaltung mit bestimmten Webbrowsern finden Sie auf den jeweiligen Websites der Browser:

Für den Google Chrome-Browser lesen Sie bitte diese Anweisungen: https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en;
Informationen zum Firefox-Browser finden Sie hier: https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox
Für den Safari-Browser besuchen Sie bitte: https://support.apple.com/ru-ru/guide/safari/sfri11471/mac
Informationen zum Internet Explorer-Browser finden Sie unter: https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
6. Ihre Rechte gemäß DSGVO
Unter bestimmten Umständen haben Sie möglicherweise eine Reihe von Datenschutzrechten hinsichtlich der Nutzung, Speicherung und Verarbeitung Ihrer personenbezogenen Daten (z. B. das Recht, Ihre Daten zu löschen). Hier ist eine Liste der Datenschutzrechte:

Recht auf Information – wir veröffentlichen diese Datenschutzrichtlinie, um Sie darüber zu informieren, was wir mit Ihren personenbezogenen Daten tun. Sie können uns jederzeit nach personenbezogenen Daten über Sie fragen, die wir speichern. Diese Informationen betreffen unter anderem die von uns verarbeiteten Datenkategorien, zu welchen Zwecken wir sie verarbeiten, die Herkunft der Daten, sofern wir diese nicht direkt bei Ihnen erhoben haben und ggf. die Empfänger, an die wir Ihre Daten übermittelt haben.
Zugriffsrecht – Sie können uns fragen, ob wir Ihre personenbezogenen Daten verarbeiten, und Sie haben das Recht, eine Kopie der Daten anzufordern, die wir über Sie gespeichert haben.
Recht auf Berichtigung – Sie haben das Recht, unrichtige oder unvollständige Daten über Sie zu berichtigen.
Recht auf Vergessenwerden – Sie können verlangen, dass die personenbezogenen Daten, die wir über Sie gespeichert haben, aus unserem System gelöscht werden. Wir werden dieser Aufforderung nachkommen, es sei denn, wir haben einen legitimen Grund, eine gesetzliche Verpflichtung oder eine andere gesetzliche Grundlage, dies nicht zu tun. Selbst wenn wir die personenbezogenen Daten, die Gegenstand unserer aktiven (laufenden) Verarbeitungstätigkeiten sind, löschen (löschen) und ihre Verarbeitung einstellen können, werden wir diese bestimmten personenbezogenen Daten dennoch in unseren Sicherungs- und Archivspeichern aufbewahren, um unsere gesetzlichen und sonstigen Anforderungen zu erfüllen.
Recht auf Einschränkung der Verarbeitung – unter bestimmten Bedingungen können Sie uns bitten, die Verarbeitung Ihrer personenbezogenen Daten zu „blockieren“.
Recht auf Datenübertragbarkeit – Sie haben das Recht, die von uns über Sie gespeicherten Daten an eine andere Organisation übertragen zu lassen und personenbezogene Daten in einem strukturierten, allgemein verwendeten Format zu erhalten. Bitte wenden Sie sich an: info@myfuturetoken.com, um herauszufinden, ob wir derzeit die Bereitstellung der übertragbaren Datei mit den von uns über Sie verarbeiteten personenbezogenen Daten unterstützen.
Widerspruchsrecht – Sie können der Verarbeitung Ihrer Daten jederzeit aus Gründen, die sich aus Ihrer besonderen Situation ergeben, unter info@myfuturetoken.com widersprechen, sofern die Datenverarbeitung auf unserem berechtigten Interesse oder dem eines Dritten beruht. oder wenn wir Profiling durchführen, maschinelles Lernen oder automatisierte Entscheidungsalgorithmen verwenden. In diesem Fall werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten. Letzteres gilt nicht, sofern wir zwingende schutzwürdige Gründe für die Verarbeitung nachweisen können, die Ihre Interessen überwiegen, oder wir Ihre Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen.
Recht auf Widerruf der Einwilligung – Widerrufen Sie die Einwilligung, die Sie uns in Bezug auf die Verarbeitung Ihrer personenbezogenen Daten für bestimmte Zwecke erteilt haben.
Recht auf Beschwerde – wir nehmen Ihre Rechte sehr ernst. Sollten Sie jedoch der Meinung sein, dass wir Ihre Beschwerden nicht ausreichend bearbeitet haben, haben Sie das Recht, eine Beschwerde bei der zuständigen Datenschutzbehörde einzureichen. Sie können Ihre Beschwerden an die EWR-Aufsichtsbehörde Ihres Wohnsitzlandes richten.
Bei Fragen zur Ausübung der oben genannten Rechte senden Sie bitte eine E-Mail an info@myfuturetoken.com. Wenn Sie mehr über die DSGVO und Ihre Rechte erfahren möchten, ist die Website des Information Commissioner’s Office eine zuverlässige Quelle.

7. Privatsphäre von Kindern
Unsere Schnittstelle ist nicht dazu bestimmt, Daten von Personen unter 18 Jahren zu sammeln. Wir gestatten wissentlich niemandem unter 18 Jahren, Daten an unsere Schnittstelle zu übermitteln. Wenn Sie glauben, dass Ihr Kind uns möglicherweise seine Daten zur Verfügung gestellt hat, können Sie uns über die Informationen in dieser Richtlinie kontaktieren und wir werden die Daten von unserer Schnittstelle löschen.

8. Übermittlung personenbezogener Daten
Übermittlungen in Drittländer unterliegen geeigneten Garantien, insbesondere Standardvertragsklauseln, die von der Aufsichtsbehörde angenommen und von der Kommission genehmigt wurden. Auf vorherige schriftliche Anfrage können Sie eine Kopie der oben genannten geeigneten Sicherheitsvorkehrungen erhalten. Wir können Sie über weitere Schritte informieren, die zum Erhalt einer solchen Kopie erforderlich sind, einschließlich Ihrer Verpflichtung, Vertraulichkeitsverpflichtungen im Zusammenhang mit der Offenlegung der geschützten und persönlichen myfuturetoken-Informationen Dritter sowie der Bedingungen ihrer Beziehungen zu myfuturetoken einzugehen.

Beachten Sie, dass die Verwendung der auf öffentlichen Blockchains basierenden Schnittstelle darauf abzielt, Transaktionen über große Netzwerke von Computersystemen hinweg unveränderlich aufzuzeichnen. Viele Blockchains sind offen für forensische Analysen, die zur Deanonymisierung und zur unbeabsichtigten Offenlegung personenbezogener Daten führen können, insbesondere wenn Blockchain-Daten mit anderen Daten kombiniert werden. Da es sich bei Blockchains um dezentralisierte Netzwerke Dritter handelt, die nicht von uns kontrolliert oder betrieben werden, sind wir nicht in der Lage, personenbezogene Daten aus solchen Netzwerken zu löschen, zu ändern oder zu verändern.

9. Datenintegrität und Sicherheit der Verarbeitung
Wir nehmen den Datenschutz sehr ernst. Wir arbeiten hart daran, die personenbezogenen Daten, die Sie uns zur Verfügung stellen, vor Verlust, Missbrauch oder unbefugtem Zugriff zu schützen. Wir nutzen eine Vielzahl von Schutzmaßnahmen wie Verschlüsselung, digitale und physische Zugangskontrollen, Geheimhaltungsvereinbarungen und andere technische und organisatorische Maßnahmen, um die uns übermittelten personenbezogenen Daten sowohl während der Übertragung als auch im Ruhezustand zu schützen.

Bitte beachten Sie, dass die elektronische Übertragung, Speicherung oder Verarbeitung personenbezogener Daten nicht völlig sicher sein kann. Wir können nicht garantieren, dass die von uns zum Schutz personenbezogener Daten getroffenen Sicherheitsmaßnahmen niemals außer Kraft gesetzt werden oder versagen oder dass diese Maßnahmen immer ausreichend oder wirksam sind. Obwohl wir uns zum Schutz Ihrer Privatsphäre verpflichten, versprechen wir nichts und Sie sollten nicht erwarten, dass Ihre personenbezogenen Daten immer privat oder sicher bleiben.
`;

const FooterTwo = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", body: "" });

  const handleShowModal = (title, body) => {
    setModalContent({ title, body });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <footer>
      <div className="footer-area-two">
        <div className="container custom-container-four">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-8">
                <div className="footer-menu-two">
                  <ul className="navigation">
                    {/* <li>
                      <Link to="/">Über uns</Link>
                    </li>
                    <li>
                      <Link to="/">Fahrplan</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/">Blog</Link>
                    </li> */}

                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-social">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-vimeo-v"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row">
              <div className="col-lg-5">
                <div className="copyright-text">
                  <p>Urheberrechte © &copy; 2022. Alle Rechte vorbehalten MyFutureToken</p>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="scroll-up text-center">
                  <button
                    className="scroll-to-target"
                    data-target="html"
                    onClick={scrollToTop}
                  >
                    <i className="fas fa-arrow-up"></i>
                  </button>
                  <span>scroll Top</span>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="footer-bottom-menu">
                  <div>
                    <ul>
                      <li>
                        <Link to="#" onClick={() => handleShowModal("Nutzungsbedingungen", termsText)}>
                          Nutzungsbedingungen
                        </Link>
                      </li>
                      <li>
                        <Link to="#" onClick={() => handleShowModal("Datenschutzrichtlinie", privacyText)}>
                          Datenschutzrichtlinie
                        </Link>
                      </li>
                    </ul>

                    <Modal show={showModal} onHide={handleCloseModal} centered size="xl">
                      <Modal.Header closeButton>
                        <Modal.Title style={{ textAlign: 'center', width: '100%' }}>{modalContent.title}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>{modalContent.body}</p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                          X
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>

              </div>
              <div className="footer-divider"></div>
                <div className="footer-address text-center">
                <p>MFT Company</p>
                <div className="footer-divider"></div>

                  <p>United States / New York / Manhattan</p>
                </div>

            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterTwo;