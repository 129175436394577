import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap"; // react-bootstrap kütüphanesini import edin
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import logoImage from "../../../src/assets/img/logo/logo.png";
import $ from "jquery";
import Parse from "parse";
import BuyTokenWizard from "../About/BuyTokenView"; // BuyTokenWizard bileşenini import edin
import BinanceMarketCap from "../About/BinanceMarketCap";

const HeaderTwo = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [menuItem1, setmenuItem1] = useState("");
  const [menuItem2, setmenuItem2] = useState("");
  const [menuItem3, setmenuItem3] = useState("");
  const [menuItem4, setmenuItem4] = useState("");
  const [menuItem5, setmenuItem5] = useState("");
  const [menuItem6, setmenuItem6] = useState("");


  useEffect(() => {
    const MyFaceCoin = Parse.Object.extend("Menus");
    const query = new Parse.Query(MyFaceCoin);
    query.first().then((object) => {
      if (object) {
        setmenuItem1(object.get("menuItem1"));
        setmenuItem2(object.get("menuItem2"));
        setmenuItem3(object.get("menuItem3"));
        setmenuItem4(object.get("menuItem4"));
        setmenuItem5(object.get("menuItem5"));
        setmenuItem6(object.get("menuItem6"));
      } else {
        console.log("MyFaceCoin objesi bulunamadı.");
      }
    }).catch((error) => {
      console.error("Parse sorgusunda hata:", error);
    });
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [stickyClass, setStickyClass] = useState({
    fixed: "",
    header: "",
  });

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight < 245
        ? setStickyClass({ fixed: "", header: "" })
        : setStickyClass({ fixed: "active-height", header: "sticky-menu" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  useEffect(() => {
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }
  }, []);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { hash, pathname } = useLocation();
  const isActiveLink = (id) => {
    return id === hash ? "active" : "";
  };

  return (
    <header id="header">
      <div
        id="sticky-header"
        className={cn(
          "menu-area menu-style-two transparent-header",
          stickyClass.header
        )}
      >
        <div className="container custom-container-three">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars"></i>
              </div>

              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                    <Link to="/">
                      <img src={logoImage} alt="" />
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li
                        className={cn(
                          hash === "" && "active",
                          " menu-item-has-children"
                        )}
                      >
                        <Link
                          to="#header"
                          className="section-link"
                          onClick={() => handleClickScroll("header")}
                        >
                          {menuItem1}
                        </Link>
                      </li>
                      <li className={cn(hash === "#about" && "active")}>
                        <Link
                          to="#about"
                          className="section-link"
                          onClick={() => handleClickScroll("about")}
                        >
                          {menuItem2}
                        </Link>
                      </li>
                      <li className={isActiveLink("#roadmap")}>
                        <Link
                          to="#roadmap"
                          className="section-link"
                          onClick={() => handleClickScroll("roadmap")}
                        >
                          {menuItem3}
                        </Link>
                      </li>
                      <li className={isActiveLink("#faq")}>
                        <Link
                          to="#faq"
                          className="section-link"
                          onClick={() => handleClickScroll("faq")}
                        >
                          {menuItem4}
                        </Link>
                      </li>
                      <li className={isActiveLink("#contact")}>
                        <Link
                          to="#contact"
                          className="section-link"
                          onClick={() => handleClickScroll("contact")}
                        >
                          {menuItem6}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul>
                      <li className="header-lang">
                        <span className="selected-lang">GER</span>
                        <ul className="lang-list">
                          <li>
                            <Link to="#">GER</Link>
                          </li>
                          <li>
                            <Link to="#">ENG</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="header-btn">
                        <button onClick={openModal} className="btn">
                          kaufe jetzt
                        </button>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times"></i>
                  </div>
                  <div className="nav-logo">
                    <Link to="/">
                      <img src={logoImage} alt="" title="" />
                    </Link>
                  </div>
                  <div className="menu-outer">
                  </div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-linkedin-in"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-youtube"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop"></div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalIsOpen}
        onHide={closeModal}
        dialogClassName="buy-token-modal"
        backdropClassName="buy-token-overlay"
        size="lg"
        centered
        animation={false}
      >
        <Modal.Body>
        <BuyTokenWizard onClose={() => {/* modal'ı kapatma işlemi */}} />
        </Modal.Body>
      </Modal>    
      </header>
  );
};

export default HeaderTwo;
