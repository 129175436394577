import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect } from "react";
import WOW from "wow.js";
import HomeTwo from "./pages/Home/HomeTwo";
import Blog from "./pages/Blog/Blog";
import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import { useLocation } from "react-router-dom";
import Parse from 'parse';
import HomeThree from "./pages/Home/HomeThree"; // HomeThree bileşenini içe aktarın


Parse.serverURL = "https://parseapi.back4app.com/"
Parse.initialize('4KRQWmAja0cl9H0lKWJEsNqBVUtMqQKz61jPlY65', 'w0mdP7fUy36pSnrGBx3CXHGsLHvFi8NfksOw6Klm');


function App() {
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  useEffect(() => {
    const TestObject = Parse.Object.extend("myfacecoin");
    const query = new Parse.Query(TestObject);
    query.find().then((results) => {
      console.log("Parse çalışıyor, bulunan objeler:", results);
    }).catch((error) => {
      console.error("Parse ile ilgili bir sorun var:", error);
    });
  }, []);


  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="home-two" element={<Home />} />
        <Route path="home-three" element={<HomeThree />} /> {/* Yeni rota */}

        <Route path="/" element={<HomeTwo />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog-details" element={<BlogDetailsPage />} />
      </Routes>
    </>
  );
}

export default App;
