import React from "react";
import Countdown from "react-countdown";

const CountDownTwo = () => {
  const targetDate = new Date('2024-06-30T00:00:00');

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <div className="time-count day">
            <span>{"00"}</span>Days
          </div>
          <div className="time-count hour">
            <span>{"00"}</span>hour
          </div>
          <div className="time-count min">
            <span>{"00"}</span>minute
          </div>
          <div className="time-count sec">
            <span>{"00"}</span>second
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="time-count day">
            <span>{days < 10 ? "0" + days : days}</span>Tag
          </div>
          <div className="time-count hour">
            <span>{hours < 10 ? "0" + hours : hours}</span>Stunde
          </div>
          <div className="time-count min">
            <span>{minutes < 10 ? "0" + minutes : minutes}</span>Minute
          </div>
          <div className="time-count sec">
            <span>{seconds < 10 ? "0" + seconds : seconds}</span>Sekunde
          </div>
        </>
      );
    }
  };

  return (
    <section id="countdown" className="countdown-area-two">
      <div className="container custom-container-four">
        <div className="row">
          <div className="col-lg-12">
            <div className="countdown-wrap">
            <h2 className="title">ICO startet in… </h2>

              <h2 className="subtitle">Die aufregende Reise beginnt jetzt! Bereiten Sie sich darauf vor, Teil der nächsten großen Innovation in der Kryptowelt zu werden. Unser ICO-Verkauf steht vor der Tür und bietet Ihnen die einmalige Gelegenheit, in MyFutureTokens (MFT) zu investieren.</h2>
              <div id="countdown-gampang"></div>

              <div className="custom-countdown-two">
                <Countdown date={targetDate} renderer={renderer} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CountDownTwo;
